import axios, {request} from "axios";
import {geturl} from "@/api/UrlConfig";
import Books from "@/views/Books";
//hook全部
export function getload(){
    let baseUrl = geturl();
    let request = axios.get(baseUrl+"/book");
    return  request;
}
//查询全部
export  function all(){
    let baseUrl = geturl();
    let request = axios.get(baseUrl+"/book");
    return request;
}

export  function getadd(book){
    let baseUrl = geturl();
    let request = axios({
        url: baseUrl + "/book",
        method: "post",
        headers: {'Content-Type':'application/json'},
        data: book
    })
    return request;
}