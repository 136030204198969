import Vue from 'vue'
import VueRouter from 'vue-router'
import Users from "@/views/Users";
import Books from "@/views/Books";
import Login from "@/views/Login";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/user',
    name: 'user',
    component: Users
  },
  {
    path: '/book',
    name: 'book',
    component: Books
  },

]

const router = new VueRouter({
  //去除导航栏#
  mode: 'history',
  routes
})
//解决路由点击重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
