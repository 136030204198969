import Vue from 'vue'
import App from './App.vue'
import router from './router'//element
import ElementUI from 'element-ui';//element
import 'element-ui/lib/theme-chalk/index.css';
import  './assets/gloable.css';

Vue.config.productionTip = false
Vue.use(ElementUI);//element

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
