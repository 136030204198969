<template xmlns:position="http://www.w3.org/1999/xhtml">
  <div>

    <el-container>

      <el-header style="background-color: #628065;position: relative">
        <div style="position: absolute;right: 20px;top: 10px">
          <el-avatar src="https://empty" @error="errorHandler">
             <el-image src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-image>
          </el-avatar>
        </div>
      </el-header>


      <el-container>
        <el-aside width="200px" style="height: 700px">

          <el-aside width="200px" style="background-color: rgb(82,125,196);height: 100%">
            <el-menu :default-openeds="['1', '2']" style="height: 100%;overflow-x: hidden"
                     background-color="#628065"
                     text-color="#fff"
            >
              <el-submenu index="1">
                <template slot="title"><i class="el-icon-message"></i>菜单选择</template>
                <el-menu-item-group>
                  <template slot="title">管理功能</template>
                  <el-menu-item index="1-1" @click="userM">用户管理</el-menu-item>
                  <el-menu-item index="1-2" @click="bookM">书籍管理</el-menu-item>

                </el-menu-item-group>
              </el-submenu>

            </el-menu>
          </el-aside>

        </el-aside>


        <el-main>
          <div style="display: block">
            <div style="display: flex;margin-left: 20px">
              <el-input v-model="input" placeholder="请输入用户ID"
                        style="width: 400px;margin-right: 10px"></el-input>

              <el-row>
                <el-button type="primary" @click="getbyid">搜索</el-button>
              </el-row>

              <div style="margin-left: 20px">
                <el-button type="primary" @click="dialogFormVisible = true">添加用户</el-button>

                <el-dialog title="添加用户" :visible.sync="dialogFormVisible">
                  <el-form :model="form">
                    <el-form-item label="用户ID" :label-width="formLabelWidth">
                      <el-input v-model="form.uid" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名" :label-width="formLabelWidth">
                      <el-input v-model="form.userName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                      <el-input v-model="form.passWord" type="password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="真实名字" :label-width="formLabelWidth">
                      <el-input v-model="form.realName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" :label-width="formLabelWidth">
                      <el-input v-model="form.uaddress" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                      <el-input v-model="form.uphone" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="adduser">确 定</el-button>
                  </div>
                </el-dialog>

                <el-button type="primary" @click="getall" style="margin-left: 20px">查询全部用户信息</el-button>
              </div>
            </div>
          </div>


          <el-table
              :data="tableData"
              style="width: 100%;">
            <el-empty description="描述文字"></el-empty>

            <el-table-column
                label="ID序号"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.uid }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="用户名"
                width="180">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <p>用户名: {{ scope.row.userName }}</p>
                  <p>真实姓名: {{ scope.row.realName }}</p>
                  <p>用户密码: {{ scope.row.passWord }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">{{ scope.row.userName }}</el-tag>
                  </div>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column
                label="地址"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.uaddress }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="手机号"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.uphone }}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作">
              <template slot-scope="scope">


                <el-button size="mini" @click="dialogEdit = true" style="margin-right: 10px">编辑</el-button>

                <el-dialog title="编辑书籍" :visible.sync="dialogEdit">
                  <el-form :model="formedit">
                    <el-form-item label="用户ID" :label-width="formLabelWidth">
                      <el-input v-model="formedit.uid" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名" :label-width="formLabelWidth">
                      <el-input v-model="formedit.userName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                      <el-input v-model="formedit.passWord" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="真实名字" :label-width="formLabelWidth">
                      <el-input v-model="formedit.realName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" :label-width="formLabelWidth">
                      <el-input v-model="formedit.uaddress" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                      <el-input v-model="formedit.uphone" autocomplete="off"></el-input>
                    </el-form-item>

                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogEdit = false">取 消</el-button>
                    <el-button type="primary" @click="Editbook(scope.$index, scope.row)">确 定</el-button>
                  </div>
                </el-dialog>


                <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>
              </template>
            </el-table-column>

          </el-table>

          <el-pagination style="margin-top: 255px;position: fixed"
              background
              layout="prev, pager, next"
              :total="100">
          </el-pagination>
        </el-main>

      </el-container>
    </el-container>

  </div>
</template>

<script>
import axios from "axios";
import {geturl} from "@/api/UrlConfig";

export default {
  mounted() {
    document.title = '图书馆管理系统'
  },

  name: "Users",

  data() {
    return {
      dialogFormVisible: false,
      dialogEdit:false,
      formLabelWidth: '120px',
      form: {
        uid: '',
        userName: '',
        passWord: '',
        realName: '',
        uaddress: '',
        uphone: '',
      },
      formedit: {
        uid: '',
        userName:'',
        passWord: '',
        realName:'',
        uaddress:'',
        uphone:'',
      },

      input: '',
      tableData: [],
    }
  },

  created() {
    this.load();
  },

  methods: {
    //点击菜单事件
    userM() {
      this.$router.push("/user");
    },
    bookM() {
      this.$router.push("/book");
    },

    //查询全部用户信息，在加载时使用的方法。
    load() {
      axios.get(geturl()+"/user").then(res => {
        this.$data.tableData = res.data.data;
      }).catch(err => {
        alert("请求服务器失败，请重试！")
      })
    },
    //查询用户全部信息
    getall() {
      axios.get(geturl()+"/user").then(res => {
        this.$data.tableData = res.data.data;
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });

        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
          });
        }

      }).catch(err => {
        alert("请求服务器失败，请重试！")
      })
    },
    //据ID查询用户信息
    getbyid() {
      if (this.$data.input != null) {
        axios.get(geturl()+"/user/" + this.$data.input).then(res => {
          console.log(res);
          this.$data.tableData = res.data.data;

          if (res.data.code = 200) {
            this.$notify({
              title: '成功',
              message: res.data.msg,
              type: 'success'
            });
          } else {
            this.$notify.error({
              title: '错误',
              message: res.data.msg,
            });
          }
          ;
        }).catch(err => {
          alert("请求服务器失败，请重试！")
        })
      } else {
        alert("非法输入或空值查询，请输入ID进行查询！");
      }
    },
    //新增用户信息
    adduser() {
      let user = this.form  //obj=obj
      axios({
        url: geturl()+"/user",
        method: "post",
        headers: {'Content-Type': 'application/json'},
        data: user
      }).then(res => {
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
          this.dialogFormVisible = false;//关闭对话框
          this.load();//调用load
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
          });
          this.load();//调用load
        }
        ;
      }).catch(err => {
        this.$message.error('已经存在该用户ID序号，请勿重复添加！');
      })
    },
    //修改用户信息
    Editbook (index,row) {
  let user = this.formedit
  console.log(index,row);

  axios({
    url: geturl()+"/user",
    method: "put",
    headers: {'Content-Type':'application/json'},
    data: user
  }).then(res => {
    console.log(res);

    if (res.data.code = 200) {
      this.$notify({
        title: '成功',
        message: res.data.msg,
        type: 'success'
      });
      this.dialogEdit = false;//关闭对话框
      this.load();//调用load
    }

  })
    },
    //删除用户信息
    handleDelete(index, row) {
      console.log(index, row);
      axios.delete(geturl()+'/user/' + row.uid).then(res => {
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
          this.load();
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
            type: 'error',
          });
          this.load();//调用load
        }
        ;

      }).catch(err => {
        alert("请求服务器失败，请重试！")
      })
    },


  }
}
</script>

<style>

</style>