<template xmlns:position="http://www.w3.org/1999/xhtml">
  <div>

    <el-container>

      <el-header style="background-color: #628065;position: relative">
        <div style="position: absolute;right: 20px;top: 10px">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
        </div>
      </el-header>


      <el-container>
        <el-aside width="200px" style="height: 700px">

          <el-aside width="200px" style="background-color: rgb(82,125,196);height: 100%">
            <el-menu :default-openeds="['1', '2']" style="height: 100%;overflow-x: hidden"
                     background-color="#628065"
                     text-color="#fff"
            >
              <el-submenu index="1">
                <template slot="title"><i class="el-icon-message"></i>菜单选择</template>
                <el-menu-item-group>
                  <template slot="title">管理功能</template>
                  <el-menu-item index="1-1" @click="userM">用户管理</el-menu-item>
                  <el-menu-item index="1-2" @click="bookM">书籍管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>

            </el-menu>
          </el-aside>

        </el-aside>


        <el-main>
          <div style="display: block">
            <div style="display: flex;margin-left: 20px">
              <el-input v-model="input" placeholder="请输入书籍ID"style="width: 400px;margin-right: 10px"></el-input>
              <el-row>
                <el-button type="primary" @click="getbyid">搜索</el-button>
              </el-row>

              <div style="margin-left: 20px">
                <el-button type="primary" @click="dialogFormVisible = true">添加书籍</el-button>

                <el-dialog title="添加书籍" :visible.sync="dialogFormVisible">
                  <el-form :model="form">
                    <el-form-item label="书籍ID" :label-width="formLabelWidth">
                      <el-input v-model="form.bisbn" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="书名" :label-width="formLabelWidth">
                      <el-input v-model="form.bname" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="作者" :label-width="formLabelWidth">
                      <el-input v-model="form.bauthor" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="出版社" :label-width="formLabelWidth">
                      <el-input v-model="form.bpress" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="售价" :label-width="formLabelWidth">
                      <el-input v-model="form.bprice" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="数量" :label-width="formLabelWidth">
                      <el-input v-model="form.bnum" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="上架时间" :label-width="formLabelWidth">
                      <el-input v-model="form.bbuydate" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="书籍图片(仅URL)" :label-width="formLabelWidth">
                      <el-input v-model="form.bimage" autocomplete="off"></el-input>
<!--                      <el-upload-->
<!--                          class="avatar-uploader"-->
<!--                          action="https://jsonplaceholder.typicode.com/posts/"-->
<!--                          :show-file-list="false"-->
<!--                          :on-success="handleAvatarSuccess"-->
<!--                          :before-upload="beforeAvatarUpload">-->
<!--                        <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
<!--                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--                      </el-upload>-->

                    </el-form-item>

                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="Addbook">确 定</el-button>
                  </div>
                </el-dialog>

                <el-button type="primary" @click="getall" style="margin-left: 20px">查询全部书籍信息</el-button>
              </div>
            </div>
          </div>


          <el-table
              :data="tableData"
              style="width: 100%;">
            <el-empty description="描述文字"></el-empty>

            <el-table-column
                label="书籍ID"
                width="100">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.bisbn }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="书名"
                width="250">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.bname }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="作者"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.bauthor }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="出版社"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.bpress }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="数量"
                width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.bnum }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="书籍图片"
                width="180">
              <template slot-scope="scope">
<!--                <span style="margin-left: 10px">{{ scope.row.bimage }}</span>-->
                <el-image :src="scope.row.bimage" style="width: 97px;height: 97px"></el-image>
              </template>
            </el-table-column>

            <el-table-column label="操作">
              <template slot-scope="scope">

                  <el-button size="mini" @click="dialogEdit = true" style="margin-right: 10px">编辑</el-button>

                  <el-dialog title="编辑书籍" :visible.sync="dialogEdit">
                    <el-form :model="formedit">
                      <el-form-item label="书籍ID" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bisbn" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="书名" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bname" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="作者" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bauthor" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="出版社" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bpress" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="售价" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bprice" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="数量" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bnum" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="上架时间" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bbuydate" autocomplete="off"></el-input>
                      </el-form-item>

                      <el-form-item label="书籍图片" :label-width="formLabelWidth">
                        <el-input v-model="formedit.bimage" autocomplete="off"></el-input>
<!--                        <el-upload-->
<!--                            class="avatar-uploader"-->
<!--                            action="https://jsonplaceholder.typicode.com/posts/"-->
<!--                            :show-file-list="false"-->
<!--                            :on-success="handleAvatarSuccess"-->
<!--                            :before-upload="beforeAvatarUpload">-->
<!--                          <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
<!--                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--                        </el-upload>-->
                      </el-form-item>

                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogEdit = false">取 消</el-button>
                      <el-button type="primary" @click="Editbook(scope.$index, scope.row)">确 定</el-button>
                    </div>
                  </el-dialog>

                <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>

              </template>
            </el-table-column>
          </el-table>

          <el-pagination style="margin-top: 10px;position: fixed"
                         background
                         layout="prev, pager, next"
                         :total="100">
          </el-pagination>

        </el-main>

      </el-container>
    </el-container>

  </div>
</template>

<script>
import axios from "axios";
import {geturl} from "@/api/UrlConfig.js";
import {getload,all,getadd} from "@/api/BookRequest";

export default {
  mounted() {
    document.title = '图书馆管理系统'
  },

  name: "book",

  data() {
    return {
      dialogFormVisible: false,
      dialogEdit:false,
      formLabelWidth: '120px',
      form: {
        bisbn: '',
        bname:'',
        bauthor: '',
        bpress: '',
        bprice:'',
        bnum:'',
        bbuydate:'',
        bimage:'http://www.tup.tsinghua.edu.cn/upload/bigbookimg/103428-01.jpg'
      },
      formedit: {
        bisbn: '',
        bname:'',
        bauthor: '',
        bpress: '',
        bprice:'',
        bnum:'',
        bbuydate:'',
        bimage:'http://www.tup.tsinghua.edu.cn/upload/bigbookimg/103428-01.jpg'
      },

      input: '',
      tableData: [],
    }
  },

  created() {
    this.load();
  },

  methods: {
    //点击菜单事件
    userM() {
      this.$router.push("/user");
    },
    bookM() {
      this.$router.push("/book");
    },

    //查询全部信息，在加载时使用的方法。
    load(){
        getload().then(res => {
        this.$data.tableData = res.data.data;
      })
    },
    //查询全部信息
    getall() {
        all().then(res => {
        this.$data.tableData = res.data.data;
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
          });
        }

      })
    },
    //据ID查询书籍信息
    getbyid() {
      if (this.$data.input != null) {
        // axios.get("http://localhost:8081/book/" + this.$data.input)
          axios.get(geturl()+"/book/" + this.$data.input).then(res => {
          console.log(res);
          this.$data.tableData = res.data.data;

          if (res.data.code = 200) {
            this.$notify({
              title: '成功',
              message: res.data.msg,
              type: 'success'
            });
          } else {
            this.$notify.error({
              title: '错误',
              message: res.data.msg,
            });
          };
        });
      } else {
        alert("非法输入或空值查询，请输入ID进行查询！");
      }
    },
    //新增书籍信息
    Addbook() {
      let book = this.form

       getadd(book).then(res => {
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
          this.dialogFormVisible = false;//关闭对话框
          this.load();//调用load
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
          });
          this.load();//调用load
        };

      })
    },
    //修改书籍信息
    Editbook(index,row) {
      let book = this.formedit
      console.log(index,row);

      axios({
        url: geturl()+"/book",
        method: "put",
        headers: {'Content-Type':'application/json'},
        data: book
      }).then(res => {
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
          this.dialogEdit = false;//关闭对话框
          this.load();//调用load
        }

      })
    },
    //删除书籍信息
    handleDelete(index,row) {
      console.log(index, row);
      axios.delete(geturl()+'/book/'+row.bisbn).then(res=>{
        console.log(res);

        if (res.data.code = 200) {
          this.$notify({
            title: '成功',
            message: res.data.msg,
            type: 'success'
          });
          this.load();
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg,
            type: 'error',
          });
          this.load();//调用load
        };

      })
    }

  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>